import ApiService from "@/core/services/api/v2/api.service";

const VEHICLE_STATUS_LIST_KEY = "VehicleStatusList";

const VehicleStatusService = {
  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(VEHICLE_STATUS_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("vehicleStatus")
          .then(response => {
            let newList = response.data.response.VehicleStatuses;

            window.sessionStorage.setItem(
              VEHICLE_STATUS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(VEHICLE_STATUS_LIST_KEY);
  }
};

export default VehicleStatusService;
