import ApiService from "@/core/services/api/v2/api.service";

const VEHICLE_FILTER_KEY = "VehicleFilter";

const VehicleService = {
  getProfile(vehicleID) {
    return new Promise(resolve => {
      ApiService.get("vehicle/" + vehicleID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getPlate(vehicleID) {
    return new Promise(resolve => {
      ApiService.get("vehicle/" + vehicleID + "/plate")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAddressHistory(vehicleID) {
    return new Promise(resolve => {
      ApiService.get("vehicle/" + vehicleID + "/addressHistory")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(vehicleID, newData) {
    return new Promise(resolve => {
      ApiService.put("vehicle/" + vehicleID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(vehicleID) {
    return new Promise(resolve => {
      ApiService.delete("vehicle/" + vehicleID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("vehicle", newData)
        .then(response => {
          // Response (if correct) holds the new VehicleID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByVehicleTypeID,
    filterByVehicleStatusID,
    filterByVehicleModelID,
    filterByBrand,
    filterByModel,
    filterByVersion,
    filterByActive,
    filterByIsTravelling,
    orderBy,
    orderDesc,
    limit,
    offset,
    cacheFilters
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied (unless cacheFilters=false)
      const queryParams = {};

      // Search text
      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      // VehicleType & VehicleStatus, ints
      filterByVehicleTypeID = parseInt(filterByVehicleTypeID);
      if (!isNaN(filterByVehicleTypeID) && filterByVehicleTypeID > 0) {
        queryParams.VehicleTypeID = filterByVehicleTypeID;
      }

      filterByVehicleStatusID = parseInt(filterByVehicleStatusID);
      if (!isNaN(filterByVehicleStatusID) && filterByVehicleStatusID > 0) {
        queryParams.VehicleStatusID = filterByVehicleStatusID;
      }

      filterByVehicleModelID = parseInt(filterByVehicleModelID);
      if (!isNaN(filterByVehicleModelID) && filterByVehicleModelID > 0) {
        queryParams.VehicleModelID = filterByVehicleModelID;
      }

      // Brand & Model & Version, strings
      filterByBrand = filterByBrand.trim();
      if (filterByBrand.length > 0) {
        queryParams.Brand = filterByBrand;
      }

      filterByModel = filterByModel.trim();
      if (filterByModel.length > 0) {
        queryParams.Model = filterByModel;
      }

      filterByVersion = filterByVersion.trim();
      if (filterByVersion.length > 0) {
        queryParams.Version = filterByVersion;
      }

      // Active & IsTravelling, booleans sent as strings "true" / "false"
      filterByActive = filterByActive.trim();
      if (filterByActive.length > 0) {
        queryParams.Active = filterByActive;
      }

      filterByIsTravelling = filterByIsTravelling.trim();
      if (filterByIsTravelling.length > 0) {
        queryParams.IsTravelling = filterByIsTravelling;
      }

      if (cacheFilters) {
        window.sessionStorage.setItem(
          VEHICLE_FILTER_KEY,
          JSON.stringify(queryParams)
        );
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("vehicle", { params: queryParams })
        .then(response => {
          // Return full response, not just "Vehicles" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(VEHICLE_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  downloadExcelTemplate() {
    return new Promise(resolve => {
      ApiService.getBlob("vehicle/import")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  uploadExcel(newFile) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("Excel", newFile);

      ApiService.post("vehicle/import", formData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default VehicleService;
